import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import './sidebar.css';

function Sidebar() {

    // Define state for toggling sidebar collapse
    const [isCollapsed, setIsCollapsed] = useState(true);

    // Toggle the collapsed state
    const toggleSidebar = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
            <header className='sidebar-header'>
                <button className='toggler sidebar-toggler' onClick={toggleSidebar}>
                    <span className="material-symbols-rounded">chevron_left</span>
                </button>
            </header>

            <nav className="sidebar-nav">
                <ul className="nav-list primary-nav">
                    <li className="nav-item">
                        <Link to="/" className="nav-link">
                            <span className="nav-icon material-symbols-rounded">dashboard</span>
                            <span className="nav-label">Dashboard</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/train" className="nav-link">
                            <span className="nav-icon material-symbols-rounded">model_training</span>
                            <span className="nav-label">Train</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/reports" className="nav-link">
                            <span className="nav-icon material-symbols-rounded">lab_profile</span>
                            <span className="nav-label">Reports</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/manage" className="nav-link">
                            <span className="nav-icon material-symbols-rounded">manage_accounts</span>
                            <span className="nav-label">Manage</span>
                        </Link>
                    </li>
                </ul>

                <ul className="nav-list secondary-nav">
                    <li className="nav-item">
                        <Link to="/profile" className="nav-link">
                            <span className="nav-icon material-symbols-rounded">account_circle</span>
                            <span className="nav-label">Profile</span>
                        </Link>
                    </li>
                </ul>
            </nav>
        </div>
    );
}

export default Sidebar;
