// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  min-height: 100vh;
  margin: 0;
}

.App {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
}

.sidebar {
  transition: width 0.4s ease;
  box-shadow: inset -2px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.main-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: radial-gradient(farthest-corner at 80% 80%, #a6c9ff, #f8fbff 80%);
  position: relative;
  transition: margin-left 0.4s ease;
  /* box-shadow: inset 12px 0 8px -8px rgba(0, 0, 0, 0.25); */
  box-sizing: border-box;
  height: 100%;
  overflow-x: auto;
}

.sidebar.collapsed + .main-content {
  margin-left: 85px;
}

.sidebar:not(.collapsed) + .main-content {
  margin-left: 270px;
}

.cevian-logo {
  padding: 10px;
  position: fixed;
  top: 0;
  right: 0;
  width: 10%;
  min-width: 90px;
}

.cevian-logo img{
  width: 100%;
  height: 100%;
}

.ask-cevian {
  padding: 10px;
}

.ask-cevian img{
  width: 100%;
  height: 100%;
}

.footer {
  position: absolute;
  bottom: 8px;
  color: #7B7C8E;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,aAAa;AACf;;AAEA;EACE,2BAA2B;EAC3B,sDAAsD;AACxD;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6EAA6E;EAC7E,kBAAkB;EAClB,iCAAiC;EACjC,2DAA2D;EAC3D,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,MAAM;EACN,QAAQ;EACR,UAAU;EACV,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,cAAc;AAChB","sourcesContent":["body {\n  min-height: 100vh;\n  margin: 0;\n}\n\n.App {\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  height: 100vh;\n}\n\n.sidebar {\n  transition: width 0.4s ease;\n  box-shadow: inset -2px 0px 4px 0px rgba(0, 0, 0, 0.25);\n}\n\n.main-content {\n  flex-grow: 1;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  background: radial-gradient(farthest-corner at 80% 80%, #a6c9ff, #f8fbff 80%);\n  position: relative;\n  transition: margin-left 0.4s ease;\n  /* box-shadow: inset 12px 0 8px -8px rgba(0, 0, 0, 0.25); */\n  box-sizing: border-box;\n  height: 100%;\n  overflow-x: auto;\n}\n\n.sidebar.collapsed + .main-content {\n  margin-left: 85px;\n}\n\n.sidebar:not(.collapsed) + .main-content {\n  margin-left: 270px;\n}\n\n.cevian-logo {\n  padding: 10px;\n  position: fixed;\n  top: 0;\n  right: 0;\n  width: 10%;\n  min-width: 90px;\n}\n\n.cevian-logo img{\n  width: 100%;\n  height: 100%;\n}\n\n.ask-cevian {\n  padding: 10px;\n}\n\n.ask-cevian img{\n  width: 100%;\n  height: 100%;\n}\n\n.footer {\n  position: absolute;\n  bottom: 8px;\n  color: #7B7C8E;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
