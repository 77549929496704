// import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './train.css';

function Train(){
    return (
        <div className="content-sources">
            <div className="train-heading">
                <span>Content Sources</span>
            </div>
            <div className="sources">
                <Link to = "/documenthub" className="document-hub">
                    <span class="material-symbols-outlined">document_scanner</span>
                    <span>Document Hub</span>
                </Link>
                
                <div className="sharepoint">
                    <img src="/sharepoint-icon.png" alt="Sharepoint" />
                    <span>Sharepoint</span>
                </div>
            </div>
        </div>
    )
}
export default Train;