import { useRef, useState, useEffect } from 'react';
import './documenthub.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

function DocumentHub() {
    const [selectedFolderIndex, setSelectedFolderIndex] = useState();
    const [newFolder, setNewFolder] = useState(false);
    const [folderData, setFolderData] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const fileInputRef = useRef(null);
    const [fileData, setFileData] = useState([]);

    const fetchFolders = async () => {
        try {
            const response = await fetch(`${BACKEND_URL}/folders_list`);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setFolderData(data);
            if (data.length > 0) {
                fetchFileData(0); // Fetch files for the first folder initially 
            }
        } catch (error) {
            console.error("Error fetching folders:", error);
        }
    };

    useEffect(() => {
        fetchFolders();
    }, []);

    const handleDivClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = async (e) => {
        const files = e.target.files;
        if (!files.length) return;

        setUploading(true);
        setUploadProgress(0);

        const formData = new FormData();
        Array.from(files).forEach(file => {
            formData.append('files', file);
        });

        const folderId = folderData[selectedFolderIndex]?._id || '';

        try {
            const response = await fetch(`${BACKEND_URL}/upload?id=${folderId}`, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error(`Upload failed with status: ${response.status}`);
            }

            const result = await response.json();
            console.log('Upload successful:', result);

            e.target.value = ''; // Reset file input
            await fetchFileData(selectedFolderIndex); // Refresh files after upload
            setUploading(false);
            setUploadProgress(0);
        } catch (error) {
            console.error('Error uploading files:', error);
            setUploading(false);
        }
    };

    const fetchFileData = async (folderIndex) => {
        if (!folderData[folderIndex]) return; // Avoid errors if folder doesn't exist

        const folderId = folderData[folderIndex]._id;
        setFileData([]); // Clear existing files while fetching new ones
        try {
            const response = await fetch(`${BACKEND_URL}/retrive_files?folder_id=${folderId}`);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setFileData(data.documents || []); // Safely handle `documents` key
        } catch (error) {
            console.error("Error fetching files data:", error);
        }
    };

    useEffect(() => {
        fetchFileData(selectedFolderIndex);
    }, [selectedFolderIndex]);

    return (
        <div className="datapage">
            <div className="heading">
                <h1>
                    <a href="/train">Content Sources</a>
                </h1>
                <h1> &nbsp;&gt; Document Hub</h1>
            </div>

            <div className="data">
                <div className="folders-section">
                    <div className="folders-section-head">
                        <input type="text" placeholder='Search Folders' />
                        <div className="create-new-folder" onClick={() => setNewFolder(true)}>
                            <span className="material-symbols-outlined">create_new_folder</span>
                        </div>
                    </div>
                    {newFolder === true ? <NewFolder setAddNewFolder={setNewFolder} fetchFolders={fetchFolders} /> : null}
                    <div className="folders">
                        {folderData.map((folder, index) => (
                            <Folder
                                key={index}
                                name={folder.folder_name}
                                onClick={() => setSelectedFolderIndex(index)}
                                isSelected={selectedFolderIndex === index}
                            />
                        ))}
                    </div>
                </div>

                <div className="files-section">
                    <div className="files-section-head">
                        <div
                            className={`upload-container ${uploading ? 'uploading' : ''}`}
                            onClick={handleDivClick}
                        >
                            <span className="material-symbols-outlined">attach_file_add</span>
                            <span>{uploading ? `Uploading... ${uploadProgress}%` : 'Add Your Files'}</span>
                            {uploading && (
                                <div className="progress-bar">
                                    <div
                                        className="progress"
                                        style={{ width: `${uploadProgress}%` }}
                                    ></div>
                                </div>
                            )}
                        </div>
                        <input
                            type="file"
                            className="fileInput"
                            ref={fileInputRef}
                            multiple
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                        />
                        {/* {error && <div className="error-message">{error}</div>} */}
                    </div>

                    <div className="meta-heading">
                        <div className="meta-heading-left">
                            <span>File Name</span>
                        </div>
                        <div className="meta-heading-right">
                            <span>File Type</span>
                            <span>File Size</span>
                            <span>Date Uploaded</span>
                        </div>
                    </div>

                    <div className="files">
                        {fileData.map((doc, index) => (
                            <File
                                key={index}
                                fileName={doc.file}
                                fileType={doc.content_type}
                                fileSize={`${doc.size} KB`}
                                dateCreated={doc.uploaded_at}
                                fileUrl={doc.path}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}


function Folder({ name, onClick }) {
    return (
        <div className="folder" onClick={onClick}>
            <div className="folder-icon">
                <span className="material-symbols-outlined">folder</span>
            </div>
            <div className="folder-name">
                <h3>{name}</h3>
            </div>
        </div>
    );
}

function NewFolder({setAddNewFolder, fetchFolders}) {
    const [inputValue, setInputValue] = useState("");

    const handleCreateFolder = async (folderName) => {

        const apiUrl = `${BACKEND_URL}/folder?folder_name=${encodeURIComponent(folderName)}`; // Replace with your actual API URL

        try {
            const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
                "accept": "application/json", // Match the curl command's headers
            },
            body: "", // Empty body, as in your curl command
            });

            if (response.ok) {
                setInputValue(""); // Reset input
                setAddNewFolder(false); // Close new folder input
                await fetchFolders(); // Refresh folder data after new folder creation
            }
        } catch (error) {
            console.error("Error creating folder:", error);
        }
    };

    const handleSubmit = () => {
        handleCreateFolder(inputValue.trim());
    };

    return (
        <div className="folder">
            <div className="folder-icon">
                <span className="material-symbols-outlined">folder</span>
            </div>
            <div className="folder-name">
                <input
                    type="text"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={(e) => e.key === "Enter" && handleSubmit()}
                    placeholder="Enter Folder Name"
                />
            </div>
        </div>
    );
}


function File({ fileName, fileType, fileSize, dateCreated, fileUrl }) {
    const [showMenu, setShowMenu] = useState(false);

    const toggleMenu = () => setShowMenu(!showMenu);

    const handleDelete = () => {
        console.log(`Deleting ${fileName}`);
        setShowMenu(false);
    };

    const handleDownload = () => {
        console.log(`Downloading ${fileName}`);
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setShowMenu(false);
    };

    const handleRename = () => {
        const newName = prompt(`Rename "${fileName}" to:`, fileName);
        if (newName) {
            console.log(`Renaming ${fileName} to ${newName}`);
        }
        setShowMenu(false);
    };

    const displayFileName = fileName.length > 8 ? `${fileName.substring(0, 8)}...` : fileName;
    const displayFileType = fileType.includes('/') ? fileType.split('/').pop() : fileType;
    const displayDateCreated = dateCreated.length > 10 ? `${dateCreated.substring(0, 10)}` : dateCreated;

    return (
        <div className="file">
            <div className="file-left">
                <span className="material-symbols-outlined">description</span>
                <span>{displayFileName}</span>
            </div>

            <div className="file-right">
                <span>{displayFileType}</span>
                <span>{fileSize}</span>
                <span>{displayDateCreated}</span>
                <div className="file-menu-button">
                    <span
                        className="material-symbols-outlined"
                        onClick={toggleMenu}
                    >
                        menu
                    </span>
                    {showMenu && (
                        <div className="dropdown-menu">
                            <button onClick={handleDelete}>Delete</button>
                            <button onClick={handleDownload}>Download</button>
                            <button onClick={handleRename}>Rename</button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default DocumentHub;