import React, { useEffect } from 'react';
import './dashboard.css';

function Dashboard() {
  return (
    <div className="dashboard">
        <div className="ask-cevian">
            <img src="/AskCevian.png" alt="Ask Cevian" />
        </div>
        <div className="footer">
            {
                PromptBox()
            }
        </div>
    </div>
)
}

export default Dashboard


            //  --------------------  PromptBox Function---------------


function PromptBox(){

    useEffect(() => {
        const textarea = document.querySelector('.input-field');

        const handleInput = () => {
            textarea.style.height = 'auto';
            textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`;
        };
        textarea.addEventListener('input', handleInput);
        return () => {
            textarea.removeEventListener('input', handleInput);
        };
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        const inputField = e.target.querySelector('.input-field');
        const message = inputField.value;
        console.log(message);
        inputField.value = '';
        inputField.style.height = 'auto';
    };

    return (
        <div className='promptbox'>
            <form onSubmit={handleSubmit} className="input-form">
                <textarea placeholder="Message Cevian" className="input-field" />
                <button type="submit" className="input-button">
                    <img src="/send-icon.png" alt="Send" />
                </button>
            </form>
        </div>
    );
}
